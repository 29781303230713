<template>
	<div style="border: 1px solid #ccc;padding:15px;background-color: #f5f5f5;">
		<div style="padding: 15px;background-color: #fff;">
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">分类名称：</label>
				<el-input placeholder="请输入分类名称" style="width:90%;" v-model="typedata.name"></el-input>
			</div>
			<div style="display: flex;margin: 20px 0;">
				<label style="margin-right: 10px;line-height:40px;">分类描述：</label>
				<el-input type="textarea" :rows="2" style="width: 60%;" placeholder="请输入分类描述内容"
					v-model="typedata.description">
				</el-input>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:240px;">分类图片：</label>
				<el-upload class="avatar-uploader"
					style="border: 1px solid #ebebeb;margin: 20px;padding: 5px;border-radius:15px;"
					:show-file-list="false" :action="$ips+'/common/upfile'" 
					:on-success="upload">
					<img v-if="typedata.imgurl" :src="typedata.imgurl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>

			<div style="display: flex;justify-content: center;">
				<el-button type="primary" style="margin: 15px 0;" @click="updatanews">编辑分类</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default Vue.extend({
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				typedata: {
					name: '',
					description: '',
					updatetime: '',
					imgurl: ''
				},
				editor: null,
				html: '',
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: this.$ips + '/common/upfile',
							fieldName: 'file'
						},
						uploadVideo: {
							server: this.$ips + '/common/upfile',
							fieldName: 'file',
							maxFileSize: 100 * 1024 * 1024, // 100M
						}
					}
				},
				mode: 'default', // or 'simple'
			}
		},
		methods: {
			updatanews() {
				// 编辑新闻
				if (this.typedata.name == "" || this.typedata.description == '') {
					this.$message({
						showClose: true,
						message: '请填写分类名称或分类描述内容',
						type: 'error'
					});
				} else if (this.typedata.imgurl == '') {
					// 未上传图片
					this.$message({
						showClose: true,
						message: '请上传分类图片',
						type: 'error'
					});
				} else {
					let that = this
					this.$confirm('确定要编辑吗', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then((res1) => {
						console.log(res1);
						if (res1 == 'confirm') {
							console.log('确认');
							that.$post("/types/updatatype", that.typedata).then(res => {
								if (res.data == '编辑成功') {
									this.$message({
										type: 'success',
										message: '编辑成功!'
									});
								} else {
									this.$message({
										type: 'error',
										message: '编辑失败!'
									});
								}
							})
						} else {
							console.log('取消');
							this.$message({
								type: 'info',
								message: '已取消'
							});
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}
			},
			upload(e) {
				console.log(e);
				if (e.data.url) {
					this.typedata.imgurl = e.data.url
				}
			}
		},
		mounted() {
			// 模拟 ajax 请求，异步渲染编辑器
			this.$get('/types/gettype', {
				id: this.$route.params.id
			}).then(res => {
				this.typedata = res.data[0]
				this.typedata.updatetime = this.$gettime()
			})
		}
	})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #a5a5a5;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>